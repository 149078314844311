export default {
    사이트명: "Site name",
    사업명: "Business name",
    홍길동: "Hong Gildong",
    "00-0000-0000": "00-0000-0000",
    "00-000-0000": "+82 0-000-0000",
    addressTxt: "38, Digital-ro 29-gil, Guro-gu, Seoul, Republic of Korea",
    emailTxt: "email{'@'}gmail.com",

    en: {
        Login: "登录",
        Logout: "退出",
        Join: "注册",
        Mypage: "我的页面",
    },

    // header, 회원
    "모바일 메뉴 열기": "Open",
    "모바일 메뉴 닫기": "Close",
    "홈으로 이동": "Go to Home",
    로그인: "登录",
    로그아웃: "退出",
    회원가입: "注册",
    마이페이지: "我的页面",
    회원정보수정: "修改会员信息",
    "자주 묻는 질문": "常见问题",
    공지사항: "公告",
    "1:1 문의": "1:1 支持",
    개인정보: "我的数据",
    개인정보처리방침: "隐私政策",
    이용약관: "使用条款",
    "관리자 문의": "管理人员咨询",
    "아이디 저장": "保存用户名",
    "아이디/비밀번호 찾기": "查找用户名/密码",
    "아이디 찾기": "查找用户名",
    "비밀번호 찾기": "查找密码",
    "회원가입이 완료되었습니다": "注册成功。",

    // footer
    대표이사: "CEO",
    사업자등록번호: "Business registration number",
    문의번호: "Tel",
    주소: "Address",

    // 버튼
    찾기: "查找",
    검색: "搜索",
    취소: "取消",
    등록: "登记",
    다음: "下一个",
    글쓰기: "写作",
    저장하기: "保存",
    수정하기: "编辑",
    삭제하기: "删除",
    "자세히 보기": "仔细看",
    내용보기: "查看内容",
    가입하기: "提交",
    문의하기: "提交",
    인증요청: "验证身份",
    본인인증: "验证身份",
    인증완료: "验证成功",
    "확인하러 가기": "在列表中查看",
    "로그인하러 가기": "登录",

    // form
    아이디: "用户名",
    비밀번호: "密码",
    "비밀번호 확인": "重新输入密码",
    이름: "姓名",
    이메일: "电子邮件",
    연락처: "联系方式",
    내용: "内容",
    "문의 내용": "查询",
    사업자등록증: "营业执照",
    재직증명서: "在职证明",
    국가선택: "选择国家/地区",
    수신동의: "我同意接收{txt}",
    국문: "韩文",
    영문: "英文",
    한자: "中文/汉字",
    agree: {
        필수: "必选",
        선택: "可选",
    },

    // placeholder, label
    선택: "选择",
    "을 입력하세요": "请输入@.lower:{txt}。",
    "를 입력하세요": "请输入@.lower:{txt}。",
    "검색어를 입력하세요": "输入关键字。",
    "전화번호를 입력하세요": "请输入电话号码。",
    "인증번호를 입력하세요": "输入验证码。",
    "이름을 입력하세요": "请输入名字。",
    "이름 또는 기관명을 입력하세요": "输入您的姓名或您工作的机构名称。",
    "이메일을 입력하세요": "输入电子邮件地址。",
    "내용을 입력하세요": "输入文本。",
    "문의 내용을 입력하세요": "输入文本。",
    "파일을 첨부하세요": "附加文件。",
    "‘-’를 제외하고 입력하세요": "输入不包含连字符 (-) 的数字字符串。",
    "필수 입력 항목입니다": "必填条目",
    "인증절차를 진행해주세요": "请进行认证程序。",
    "아이디 찾기를 위해 등록된 핸드폰 번호를 입력해주세요": "输入您在注册时提交给我们的联系方式。",
    "영문, 숫자 포함 5자 이상 입력해주세요": "请输入包括英文和数字在内的5个字以上。",
    "영문, 숫자 5자 이상 작성해주세요": "请填写5个字以上的英文和数字。",
    "영문, 숫자, 특수기호 포함 8자 이상 입력해주세요": "请输入包括英文,数字,特殊符号在内的8个字以上。",
    "비밀번호를 입력하세요": "输入密码。",
    "동일한 비밀번호를 재입력하세요": "再次输入密码。",
    "비밀번호 찾기를 위해 등록된 핸드폰 번호를 입력해주세요": "输入您在注册时提交给我们的联系方式。",
    "자 이내": "不得超过 {num} 个字符",
    이하: "最大 {num}",
    "약관에 전체 동의합니다": "我同意以下所有条款和条件。",

    // alert
    "로그아웃 하시겠습니까?": "您要退出吗？",
    "로그아웃 되었습니다": "已注销。",

    // 게시판
    번호: "No",
    제목: "Title",
    작성자: "Writer",
    작성일: "Date",
    조회수: "Hit",
    상태: "Status",
    답변대기: "等待答复",
    답변완료: "答复完毕",

    // 결제
    신용카드: "信用卡",
    무통장입금: "无折存款",
    카카오페이: "Kakaopay",

    // page text

    // page btn

    // page placeholder, label

    // gnbs
    회사소개: "公司简介",

    // 회원가입
    국내회원: "当地会员",
    개인회원: "私人会员",
    단체회원: "团体会员",
    "국내 개인회원": "作为当地会员（私人）",
    "국내 단체회원": "作为当地会员（机构）",
    "해외거주 회원": "国际会员",
    "국내 개인 회원가입": "注册（私人）",
    "국내 단체 회원가입": "注册（机构）",
    "국내 거주자 회원가입": "注册 (当地)",
    "해외거주자 회원가입": "注册（国际）",

    // 마이페이지

    // Page
    main: {
        section1: "section1 content",
        section2: {
            title: "section2 title",
            content: "section2 content",
        },
    },
};
