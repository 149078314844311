export default {
    사이트명: "Site name",
    사업명: "Business name",
    홍길동: "Hong Gildong",
    "00-0000-0000": "00-0000-0000",
    "00-000-0000": "+82 0-000-0000",
    addressTxt: "38, Digital-ro 29-gil, Guro-gu, Seoul, Republic of Korea",
    emailTxt: "email{'@'}gmail.com",

    en: {
        Login: "Sign In",
        Logout: "Sign Out",
        Join: "Sign Up",
        Mypage: "My page",
    },

    // header, 회원
    "모바일 메뉴 열기": "Open",
    "모바일 메뉴 닫기": "Close",
    "홈으로 이동": "Go to Home",
    로그인: "Sign In",
    로그아웃: "Sign Out",
    회원가입: "Sign Up",
    마이페이지: "My Page",
    회원정보수정: "My Info",
    "자주 묻는 질문": "FAQs",
    공지사항: "Notice",
    "1:1 문의": "1:1 Support",
    개인정보: "My Data",
    개인정보처리방침: "Privacy Policy",
    이용약관: "Terms of Use",
    "관리자 문의": "Administrator inquiry",
    "아이디 저장": "Save ID",
    "아이디/비밀번호 찾기": "Find User Name / Password",
    "아이디 찾기": "Find User Name",
    "비밀번호 찾기": "Find Password",
    "회원가입이 완료되었습니다": "Registration successful.",

    // footer
    대표이사: "CEO",
    사업자등록번호: "Business registration number",
    문의번호: "Tel",
    주소: "Address",

    // 버튼
    찾기: "Find",
    검색: "Search",
    취소: "Cancel",
    등록: "Submit",
    다음: "Next",
    글쓰기: "Writing",
    저장하기: "Save",
    수정하기: "Edit",
    삭제하기: "Delete",
    "자세히 보기": "View More",
    내용보기: "View Content",
    가입하기: "Submit",
    문의하기: "Submit",
    인증요청: "Verify Identity",
    본인인증: "Verify Identity",
    인증완료: "Verification Successful",
    "확인하러 가기": "View in the List",
    "로그인하러 가기": "Sign In",

    // form
    아이디: "User Name",
    비밀번호: "Password",
    "비밀번호 확인": "Repeat password",
    이름: "Name",
    이메일: "E-mail",
    연락처: "Contact",
    내용: "Content",
    "문의 내용": "Inquiry",
    사업자등록증: "Registration of business",
    재직증명서: "Certificate of Employment",
    국가선택: "Select a country",
    수신동의: "{txt} agree to receive",
    국문: "Korean",
    영문: "English",
    한자: "Chinese",
    agree: {
        필수: "Required",
        선택: "Optional",
    },

    // placeholder, label
    선택: "Select",
    "을 입력하세요": "Enter @.lower:{txt}",
    "를 입력하세요": "Enter @.lower:{txt}",
    "검색어를 입력하세요": "Enter keyword.",
    "전화번호를 입력하세요": "Please enter your phone number.",
    "인증번호를 입력하세요": "Enter verification code.",
    "이름을 입력하세요": "Please enter your name.",
    "이름 또는 기관명을 입력하세요": "Enter your name or name of institution you work for.",
    "이메일을 입력하세요": "Enter e-mail address.",
    "내용을 입력하세요": "Enter texts.",
    "문의 내용을 입력하세요": "Enter texts.",
    "파일을 첨부하세요": "Attach a file.",
    "‘-’를 제외하고 입력하세요": "Enter a string of numbers that does not include hyphens (-).",
    "필수 입력 항목입니다": "Required entries.",
    "인증절차를 진행해주세요": "Please proceed with the authentication process.",
    "아이디 찾기를 위해 등록된 핸드폰 번호를 입력해주세요": "Enter contact that you have submitted to us when signing up.",
    "영문, 숫자 포함 5자 이상 입력해주세요": "Please enter at least 5 characters including letters and numbers.",
    "영문, 숫자 5자 이상 작성해주세요": "Please write at least 5 letters and numbers.",
    "영문, 숫자, 특수기호 포함 8자 이상 입력해주세요": "Please enter at least 8 characters including letters, numbers, and special symbols.",
    "비밀번호를 입력하세요": "Enter password.",
    "동일한 비밀번호를 재입력하세요": "Enter password again.",
    "비밀번호 찾기를 위해 등록된 핸드폰 번호를 입력해주세요": "Enter contact that you have submitted to us when signing up.",
    "자 이내": "that must not exceed {num} characters",
    이하: "up to {num}",
    "약관에 전체 동의합니다": "I agree to all terms and conditions below.",

    // alert
    "로그아웃 하시겠습니까?": "Do you want to log out?",
    "로그아웃 되었습니다": "You have been logged out.",

    // 게시판
    번호: "No",
    제목: "Title",
    작성자: "Writer",
    작성일: "Date",
    조회수: "Hit",
    상태: "Status",
    답변대기: "Waiting for reply",
    답변완료: "Answer complete",

    // 결제
    신용카드: "Credit Card",
    무통장입금: "Deposit without passbook ",
    카카오페이: "Kakaopay",

    // page text

    // page btn

    // page placeholder, label

    // gnbs
    회사소개: "About Us",

    // 회원가입
    국내회원: "Local Member",
    개인회원: "Private Member",
    단체회원: "Institution Member",
    "국내 개인회원": "As a Local Member (Private)",
    "국내 단체회원": "As a Local Member (Institution)",
    "해외거주 회원": "International Member",
    "국내 개인 회원가입": "Sign Up (Private)",
    "국내 단체 회원가입": "Sign Up (Institution)",
    "국내 거주자 회원가입": "Sign Up (Domestic)",
    "해외거주자 회원가입": "Sign Up (International)",

    // 마이페이지

    // Page
    main: {
        section1: "section1 content",
        section2: {
            title: "section2 title",
            content: "section2 content",
        },
    },
};
