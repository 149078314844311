<template>
    <div class="user-items">
        <v-row align="center" class="row--sm">
            <slot />
            <v-col v-if="accessToken" cols="auto">
                <u-btn-text-fit x-small to="/mypage/myinfo">
                    <u-icon v-if="icon" size="xx-small" :class="text ? 'mr-4px' : ''">person_outline</u-icon>
                    <span v-if="text">마이페이지</span>
                </u-btn-text-fit>
            </v-col>
            <v-col v-else cols="auto">
                <!-- <u-btn-text-fit x-small to="/join"> -->
                <u-btn-text-fit x-small to="/join/type">
                    <u-icon v-if="icon" size="xx-small" :class="text ? 'mr-4px' : ''">person_add_alt</u-icon>
                    <span v-if="text">회원가입</span>
                </u-btn-text-fit>
            </v-col>
            <v-col cols="auto"><v-divider vertical class="grey lighten-3" /></v-col>
            <v-col v-if="accessToken" cols="auto">
                <u-btn-text-fit x-small @click.native="navigate">
                    <u-icon v-if="icon" size="xx-small" :class="text ? 'mr-4px' : ''">logout</u-icon>
                    <span v-if="text">로그아웃</span>
                </u-btn-text-fit>
            </v-col>
            <v-col v-else cols="auto">
                <u-btn-text-fit x-small to="/login">
                    <u-icon v-if="icon" size="xx-small" :class="text ? 'mr-4px' : ''">login</u-icon>
                    <span v-if="text">로그인</span>
                </u-btn-text-fit>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import UBtnTextFit from "@/components/publish/styles/buttons/u-btn-text-fit.vue";

export default {
    props: {
        icon: { type: Boolean },
        text: { type: Boolean },
    },
    components: {
        UIcon,
        UBtnTextFit,
    },
    methods: {
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style lang="scss" scoped>
.user-items {
    ::v-deep {
        font-size: 1.4rem;
        color: var(--v-grey-base);

        > .row {
            > .col {
                line-height: 0;
            }
        }
        .v-btn--text.v-btn--text-fit {
            padding: 0 !important;
        }

        .v-divider {
            border-color: var(--v-grey-lighten3);
            &--vertical {
                height: 12px;
                margin-left: -1px;
            }
        }
    }

    &--light {
        .v-btn {
            color: #fff !important;
        }
    }
}
.v-application {
    .user-items--light {
        .v-divider {
            border-color: rgba(255, 255, 255, 0.2) !important;
            background-color: rgba(255, 255, 255, 0.2) !important;
        }
    }
}
</style>
