<template>
    <v-menu v-bind="$attrs" offset-y>
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
    </v-menu>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
