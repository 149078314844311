var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-chevron",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 10"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M0,8.6l0.9,0.9L8,2.3l7.1,7.2L16,8.6L8,0.5L0,8.6z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-chevron",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 10 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M8.6,16l0.9-0.9L2.3,8l7.2-7.1L8.6,0L0.5,8L8.6,16z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }