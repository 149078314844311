export default {
    사이트명: "사이트명",
    사업명: "사업명",
    홍길동: "홍길동",
    "00-0000-0000": "00-0000-0000",
    "00-000-0000": "00-000-0000",
    addressTxt: "서울특별시 구로구 디지털로29길 38 에이스테크노타워3차 707호",
    emailTxt: "email{'@'}gmail.com",

    en: {
        Login: "Login",
        Logout: "Logout",
        Join: "Join",
        Mypage: "Mypage",
    },

    // header, 회원
    "모바일 메뉴 열기": "모바일 메뉴 열기",
    "모바일 메뉴 닫기": "모바일 메뉴 닫기",
    "홈으로 이동": "홈으로 이동",
    로그인: "로그인",
    로그아웃: "로그아웃",
    회원가입: "회원가입",
    마이페이지: "마이페이지",
    회원정보수정: "회원정보수정",
    "자주 묻는 질문": "자주 묻는 질문",
    공지사항: "공지사항",
    "1:1 문의": "1:1 문의",
    개인정보: "개인정보",
    개인정보처리방침: "개인정보처리방침",
    이용약관: "이용약관",
    "관리자 문의": "관리자 문의",
    "아이디 저장": "아이디 저장",
    "아이디/비밀번호 찾기": "아이디/비밀번호 찾기",
    "아이디 찾기": "아이디 찾기",
    "비밀번호 찾기": "비밀번호 찾기",
    "회원가입이 완료되었습니다": "회원가입이 완료되었습니다.",

    // footer
    대표이사: "대표이사",
    사업자등록번호: "사업자등록번호",
    문의번호: "문의번호",
    주소: "주소",

    // 버튼
    찾기: "찾기",
    검색: "검색",
    취소: "취소",
    등록: "등록",
    다음: "다음",
    글쓰기: "글쓰기",
    저장하기: "저장하기",
    수정하기: "수정하기",
    삭제하기: "삭제하기",
    "자세히 보기": "자세히 보기",
    내용보기: "내용보기",
    가입하기: "가입하기",
    문의하기: "문의하기",
    인증요청: "인증요청",
    본인인증: "본인인증",
    인증완료: "인증완료",
    "확인하러 가기": "확인하러 가기",
    "로그인하러 가기": "로그인하러 가기",

    // form
    아이디: "아이디",
    비밀번호: "비밀번호",
    "비밀번호 확인": "비밀번호 확인",
    이름: "이름",
    이메일: "이메일",
    연락처: "연락처",
    내용: "내용",
    "문의 내용": "문의 내용",
    사업자등록증: "사업자등록증",
    재직증명서: "재직증명서",
    국가선택: "국가선택",
    수신동의: "{txt} 수신동의",
    국문: "국문",
    영문: "영문",
    한자: "한자",
    agree: {
        필수: "필수",
        선택: "선택",
    },

    // placeholder, label
    선택: "선택",
    "을 입력하세요": "@.lower:{txt}을 입력하세요.",
    "를 입력하세요": "@.lower:{txt}를 입력하세요.",
    "검색어를 입력하세요": "검색어를 입력하세요.",
    "전화번호를 입력하세요": "전화번호를 입력하세요.",
    "인증번호를 입력하세요": "인증번호를 입력하세요.",
    "이름을 입력하세요": "이름을 입력하세요.",
    "이름 또는 기관명을 입력하세요": "이름 또는 기관명을 입력하세요.",
    "이메일을 입력하세요": "이메일을 입력하세요.",
    "내용을 입력하세요": "내용을 입력하세요.",
    "문의 내용을 입력하세요": "문의 내용을 입력하세요.",
    "파일을 첨부하세요": "파일을 첨부하세요.",
    "‘-’를 제외하고 입력하세요": "‘-’를 제외하고 입력하세요.",
    "필수 입력 항목입니다": "필수 입력 항목입니다.",
    "인증절차를 진행해주세요": "인증절차를 진행해주세요.",
    "아이디 찾기를 위해 등록된 핸드폰 번호를 입력해주세요": "아이디 찾기를 위해 등록된 핸드폰 번호를 입력해주세요.",
    "영문, 숫자 포함 5자 이상 입력해주세요": "영문, 숫자 포함 5자 이상 입력해주세요.",
    "영문, 숫자 5자 이상 작성해주세요": "영문, 숫자 5자 이상 작성해주세요.",
    "영문, 숫자, 특수기호 포함 8자 이상 입력해주세요": "영문, 숫자, 특수기호 포함 8자 이상 입력해주세요.",
    "비밀번호를 입력하세요": "비밀번호를 입력하세요.",
    "동일한 비밀번호를 재입력하세요": "동일한 비밀번호를 재입력하세요.",
    "비밀번호 찾기를 위해 등록된 핸드폰 번호를 입력해주세요": "비밀번호 찾기를 위해 등록된 핸드폰 번호를 입력해주세요.",
    "자 이내": "{num}자 이내",
    이하: "{num} 이하",
    "약관에 전체 동의합니다": "약관에 전체 동의합니다.",

    // alert
    "로그아웃 하시겠습니까?": "로그아웃 하시겠습니까?",
    "로그아웃 되었습니다": "로그아웃 되었습니다.",

    // 게시판
    번호: "번호",
    제목: "제목",
    작성자: "작성자",
    작성일: "작성일",
    조회수: "조회수",
    상태: "상태",
    답변대기: "답변대기",
    답변완료: "답변완료",

    // 결제
    신용카드: "신용카드",
    무통장입금: "무통장입금",
    카카오페이: "카카오페이",

    // page text

    // page btn

    // page placeholder, label

    // gnbs
    회사소개: "회사소개",

    // 회원가입
    국내회원: "국내회원",
    개인회원: "개인회원",
    단체회원: "단체회원",
    "국내 개인회원": "국내 개인회원",
    "국내 단체회원": "국내 단체회원",
    "해외거주 회원": "해외거주 회원",
    "국내 개인 회원가입": "국내 개인 회원가입",
    "국내 단체 회원가입": "국내 단체 회원가입",
    "국내 거주자 회원가입": "국내 거주자 회원가입",
    "해외거주자 회원가입": "해외거주자 회원가입",

    // 마이페이지

    // Page
    main: {
        section1: "section1 내용",
        section2: {
            title: "section2 제목",
            content: "section2 내용",
        },
    },
};
