export default {
    사이트명: "Site name",
    사업명: "Business name",
    홍길동: "Hong Gildong",
    "00-0000-0000": "00-0000-0000",
    "00-000-0000": "+82 0-000-0000",
    addressTxt: "38, Digital-ro 29-gil, Guro-gu, Seoul, Republic of Korea",
    emailTxt: "email{'@'}gmail.com",

    en: {
        Login: "ログイン",
        Logout: "ログアウト",
        Join: "会員登録",
        Mypage: "マイページ",
    },

    // header, 회원
    "모바일 메뉴 열기": "Open",
    "모바일 메뉴 닫기": "Close",
    "홈으로 이동": "Go to Home",
    로그인: "ログイン",
    로그아웃: "ログアウト",
    회원가입: "会員登録",
    마이페이지: "マイページ",
    회원정보수정: "会員情報の修正",
    "자주 묻는 질문": "よくある質問",
    공지사항: "お知らせ",
    "1:1 문의": "1:1サポート",
    개인정보: "マイデータ",
    개인정보처리방침: "プライバシーポリシー",
    이용약관: "利用規約",
    "관리자 문의": "管理者への問い合わせ",
    "아이디 저장": "ID保存",
    "아이디/비밀번호 찾기": "ユーザー名/パスワードを忘れた場合",
    "아이디 찾기": "ユーザー名を忘れた場合",
    "비밀번호 찾기": "パスワードを忘れた場合",
    "회원가입이 완료되었습니다": "会員登録が完了しました｡",

    // footer
    대표이사: "CEO",
    사업자등록번호: "Business registration number",
    문의번호: "Tel",
    주소: "Address",

    // 버튼
    찾기: "検索",
    검색: "検索",
    취소: "取消",
    등록: "登録",
    다음: "次",
    글쓰기: "書く",
    저장하기: "保存",
    수정하기: "修正",
    삭제하기: "削除",
    "자세히 보기": "詳細を見る",
    내용보기: "内容を見る",
    가입하기: "登録",
    문의하기: "提出",
    인증요청: "本人確認",
    본인인증: "本人確認",
    인증완료: "確認完了",
    "확인하러 가기": "リスト確認",
    "로그인하러 가기": "ログイン",

    // form
    아이디: "ユーザー名",
    비밀번호: "パスワード",
    "비밀번호 확인": "パスワードの確認",
    이름: "氏名",
    이메일: "E-mail",
    연락처: "連絡先",
    내용: "内容",
    "문의 내용": "お問合せ内容",
    사업자등록증: "事業者登録証",
    재직증명서: "在職証明書",
    국가선택: "国を選択してください。",
    수신동의: "{txt}受信同意",
    국문: "韓国語",
    영문: "ローマ字",
    한자: "漢字",
    agree: {
        필수: "必須",
        선택: "選択",
    },

    // placeholder, label
    선택: "選択",
    "을 입력하세요": "@.lower:{txt}を入力してください。",
    "를 입력하세요": "@.lower:{txt}を入力してください。",
    "검색어를 입력하세요": "検索キーワードを入力してください｡",
    "전화번호를 입력하세요": "電話番号を入力してください。",
    "인증번호를 입력하세요": "認証番号を入力してください。",
    "이름을 입력하세요": "名前を入力してください。",
    "이름 또는 기관명을 입력하세요": "氏名または機関名をご記入ください｡",
    "이메일을 입력하세요": "メールアドレスをご記入ください。",
    "내용을 입력하세요": "内容を入力してください｡",
    "문의 내용을 입력하세요": "お問合せ内容をご記入ください｡",
    "파일을 첨부하세요": "データを添付してください。",
    "‘-’를 제외하고 입력하세요": "数字のみご記入ください｡",
    "필수 입력 항목입니다": "は必須項目",
    "인증절차를 진행해주세요": "認証手続きを進めてください。",
    "아이디 찾기를 위해 등록된 핸드폰 번호를 입력해주세요": "登録した携帯番号を入力してください。",
    "영문, 숫자 포함 5자 이상 입력해주세요": "英文、数字を含めて5文字以上入力してください。",
    "영문, 숫자 5자 이상 작성해주세요": "英文、数字を5文字以上作成してください。",
    "영문, 숫자, 특수기호 포함 8자 이상 입력해주세요": "英文、数字、特殊記号を含む8文字以上入力してください。",
    "비밀번호를 입력하세요": "パスワードを入力してください。",
    "동일한 비밀번호를 재입력하세요": "同じパスワードを再度入力してください｡",
    "비밀번호 찾기를 위해 등록된 핸드폰 번호를 입력해주세요": "登録した携帯番号を入力してください。",
    "자 이내": "{num}字以下",
    이하: "{num}以下",
    "약관에 전체 동의합니다": "規約に同意します。",

    // alert
    "로그아웃 하시겠습니까?": "ログアウトしますか？",
    "로그아웃 되었습니다": "ログアウトされました。",

    // 게시판
    번호: "No",
    제목: "Title",
    작성자: "Writer",
    작성일: "Date",
    조회수: "Hit",
    상태: "Status",
    답변대기: "返事待ち",
    답변완료: "回答完了",

    // 결제
    신용카드: "クレジットカード.",
    무통장입금: "振込",
    카카오페이: "Kakaopay",

    // page text

    // page btn

    // page placeholder, label

    // gnbs
    회사소개: "会社紹介",

    // 회원가입
    국내회원: "国内会員",
    개인회원: "個人会員",
    단체회원: "団体会員",
    "국내 개인회원": "国内会員(個人)",
    "국내 단체회원": "国内会員(団体)",
    "해외거주 회원": "海外会員",
    "국내 개인 회원가입": "国内会員登録(個人)",
    "국내 단체 회원가입": "国内会員登録(団体)",
    "국내 거주자 회원가입": "国内居住者会員登録",
    "해외거주자 회원가입": "海外会員登録",

    // 마이페이지

    // Page
    main: {
        section1: "section1 content",
        section2: {
            title: "section2 title",
            content: "section2 content",
        },
    },
};
