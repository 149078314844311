var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "language-items"
  }, [_vm.type == 'menu' ? [_c('menu-primary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return _vm._l(_vm.items, function (item, index) {
          return _c('div', _vm._g(_vm._b({
            key: index + 't',
            staticClass: "language-items__icon-text"
          }, 'div', attrs, false), on), [_vm.$i18n.locale == item.value ? [_vm.icon ? _c('u-icon', {
            staticClass: "language-items__icon"
          }, [_vm._v("language")]) : _vm._e(), _vm.text ? _c('span', {
            staticClass: "language-items__text"
          }, [_vm._v(_vm._s(item.country))]) : _vm._e()] : _vm._e()], 2);
        });
      }
    }], null, false, 99487306)
  }, [_c('v-list', _vm._l(_vm.items, function (item, index) {
    return _c('v-list-item', {
      key: index + 'l',
      staticClass: "cursor-pointer",
      class: _vm.$i18n.locale == item.value ? 'v-list-item--active' : '',
      on: {
        "click": function ($event) {
          _vm.$i18n.locale = item.value;
        }
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(item.country))])], 1);
  }), 1)], 1)] : _vm._e(), _vm.type == 'list' ? [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [index != 0 ? _c('v-col', {
      key: index + 'd',
      staticClass: "line-height-0",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-divider', {
      attrs: {
        "vertical": ""
      }
    })], 1) : _vm._e(), _c('v-col', {
      key: index + 't',
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "language-items__icon-text",
      class: _vm.$i18n.locale == item.value ? 'on' : '',
      on: {
        "click": function ($event) {
          _vm.$i18n.locale = item.value;
        }
      }
    }, [_vm.icon ? _c('u-icon', {
      staticClass: "language-items__icon"
    }, [_vm._v("language")]) : _vm._e(), _vm.text ? _c('span', {
      staticClass: "language-items__text"
    }, [_vm._v(_vm._s(item.country))]) : _vm._e()], 1)])];
  })], 2)] : _vm._e(), _vm.type == 'select' ? [_c('select-primary', {
    staticClass: "mw-100px",
    attrs: {
      "items": _vm.items,
      "item-text": "country",
      "item-value": "value"
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.$i18n.locale,
      callback: function ($$v) {
        _vm.$set(_vm.$i18n, "locale", $$v);
      },
      expression: "$i18n.locale"
    }
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }