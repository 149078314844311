<template>
    <svg v-if="direction == 'up' || direction == 'down'" v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" class="icon-svg icon-chevron" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M0,8.6l0.9,0.9L8,2.3l7.1,7.2L16,8.6L8,0.5L0,8.6z" />
    </svg>
    <svg v-else v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16" class="icon-svg icon-chevron" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M8.6,16l0.9-0.9L2.3,8l7.2-7.1L8.6,0L0.5,8L8.6,16z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 5px;
            }
            &-x-small {
                width: 6px;
            }
            &-small {
                width: 8px;
            }
            &-default {
                width: 10px;
            }
            &-large {
                width: 12px;
            }
            &-x-large {
                width: 14px;
            }
            &-xx-large {
                width: 16px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 5px;
            }
            &-x-small {
                height: 6px;
            }
            &-small {
                height: 8px;
            }
            &-default {
                height: 10px;
            }
            &-large {
                height: 12px;
            }
            &-x-large {
                height: 14px;
            }
            &-xx-large {
                height: 16px;
            }
        }
    }
}
</style>
